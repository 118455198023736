import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InfoComponent } from './info/info.component';


const routes: Routes = [
  {
    path: 'warnings',
    loadChildren: () => import('./warnings/warnings.module').then(m => m.WarningsModule)
  },
  { path: '',
    redirectTo: '/warnings',
    pathMatch: 'full'
  },
  {
    path: 'info',
    component: InfoComponent
  },
  { path: 'extremes', loadChildren: () => import('./extremes/extremes.module').then(m => m.ExtremesModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
