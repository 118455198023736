import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'listorama';

  @ViewChild('followSnackTpl', { read: TemplateRef }) followSnackTpl: TemplateRef<any>;
  constructor(private snackbar: MatSnackBar) {
  }

  ngOnInit() {
    const cookieNotice = this.snackbar.open(`We do not collect any personal data and` +
    ` there is no Tracking or cookie usage at all! Isn't that refreshing?`, 'yes!', {duration: 6000, });
    cookieNotice.onAction().subscribe((action) => {
      const follow = this.snackbar.openFromTemplate(this.followSnackTpl, {duration: 6000, });
    });
  }
}
