import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Warning } from '../types/Warning';
import { environment } from '../../environments/environment';
import { SearchQuery } from '../models/SearchQuery';
@Injectable({
  providedIn: 'root'
})
export class EnvAgencyService {

  private apiDomain = environment.API_DOMAIN;

  constructor(
    private httpClient: HttpClient) {
  }

  getWarnings(query: SearchQuery): Observable<{ docs: Warning[], meta: any}> {

    Object.keys(query).filter(q => !query[q]).forEach(q => delete query[q]);

    const querystring = new HttpParams({ fromObject: query as any }).toString();

    return this.httpClient.get<{ docs: Warning[], meta: any}>(
      `${this.apiDomain}/api/flood-warnings/search?${querystring}`).pipe();
  }

  getAllSearchWarnings(): Observable<any> {
    return this.httpClient.get<any>( `${this.apiDomain}/api/flood-warnings/search`);
  }

  getAllWarnings(): Observable<any> {
    return this.httpClient.get<any>( `${this.apiDomain}/api/flood-warnings`);
  }

  getWarning(slug: string): Observable<Warning[]> {
    return this.httpClient.get<Warning[]>(`${this.apiDomain}/api/flood-warnings/${slug}`);
  }
}
