<mat-toolbar color="accent">
    <h2>Info</h2>
    <div class="toolbar-spacer"></div>
</mat-toolbar>

<div fxLayout="row" fxLayoutAlign="center center">
    
    <div fxFlex="50" fxFlexAlign="center">
        <h2>About</h2>
        <p>The basic premise of the website is to collate data from various sources and to provide a history by storing a daily snapshot. 
            Listorama updates once a day so the data here could be slightly different from data from our providers.</p>
        <h3>Flood warnings</h3>
        <p>Listorama checks for flood warnings of the severity "Flood Warning" or higher, there is one level below that. 
        Listorama started gathering flood warnings in January 2016. Winter 19/20 has seen many more warnings since we started recording data.</p>
        
        <p>Please <a href="https://twitter.com/spenoir" class="twitter-follow-button" data-show-count="false">follow</a> me if you like it. Also, please get in touch if there is some feature you'd like to be added.</p>
        
        <p>This website Contains public sector information licensed under the <a href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/" title="Open Government Licence">Open Government Licence</a> v3.0.</p>

        <h2>Providers</h2>
        <p>A list of apis used:</p>
        <ul>
            <li><a href="https://www.gov.uk/government/organisations/environment-agency">Environment Agency</a></li>
            <li><a href="https://www.metoffice.gov.uk/">Met Office</a></li>
        </ul>
        <h2>Security and Tracking</h2>
        <p>We do not collect any personal data and there is no Tracking or cookie usage at all! Isn't that refreshing? 
            In the future we may consider using a basic Analytic cookie to monitor usage, this message will be updated if so.</p>
    </div>
</div>