<mat-sidenav-container>

  <mat-sidenav #sidenav mode="over">

    <mat-toolbar>
      <mat-toolbar-row>
        <h2> Listorama </h2>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-nav-list>

      <a
        mat-list-item
        (click)="sidenav.close()"
        [routerLink]="['/warnings']"
        routerLinkActive="list-item-active">
        Flood Warnings
      </a>

      <a
        mat-list-item
        (click)="sidenav.close()"
        [routerLink]="['/warnings/', 'chart']"
        routerLinkActive="list-item-active">
        All Flood Warnings
      </a>

      <a
        mat-list-item
        (click)="sidenav.close()"
        [routerLink]="['/extremes']"
        routerLinkActive="list-item-active">
        Weather Extremes
      </a>
      <mat-divider></mat-divider>
    </mat-nav-list>

  </mat-sidenav>

  <mat-sidenav-content>
    
    <mat-toolbar color="primary">

      <mat-toolbar-row>
    
        <button mat-icon-button (click)="sidenav.open()">
          <mat-icon>menu</mat-icon>
        </button>
    
        <span [routerLink]="[ '/warnings' ]">Listorama</span>
    
        <div fxFlex fxLayout fxLayoutAlign="flex-end">            
          <button mat-icon-button [routerLink]="[ '/info' ]">
            <mat-icon>info</mat-icon>
          </button>
        </div>
    
      </mat-toolbar-row>
    
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>
<ng-template #followSnackTpl>Please <a href="https://twitter.com/spenoir" class="twitter-follow-button" data-show-count="false">follow</a> me if you like it. Also, please get in touch if there is some feature you'd like to be added.</ng-template>
